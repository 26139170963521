@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #2e363d;
}

body {
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Crimson Text', serif;
} */

.App {
  width: 100%;
  height: 100%;
}
